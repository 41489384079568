import * as React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"
import Container from "../components/container"

const NotFoundPage = () => (
  <Container>
    <SEO title="404: Not found" />
    <h1 className="font-serif dark:text-slate-100 text-4xl mt-10 mb-5 font-bold underline">404: Not Found</h1>
    <p className="my-4">You just hit a route that doesn&#39;t exist... the sadness.</p>
    <Link to="/" className="hover:text-cyan-300">Go back to the homepage</Link>
  </Container>
)

export default NotFoundPage
